<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="ths_data"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="ths_data"
        :label="label"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        class="pt-0"
        v-on="on"
        :error-messages="error_messages"
        :disabled="disabled"
      ></v-text-field>
    </template>
    <v-time-picker
      format="24hr"
      v-if="menu"
      v-model="ths_data"
      full-width
      @click:minute="onChange"
    />
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    label: {
      type: [String],
      default: null
    },
    key_name: {
      type: [String],
      default: null
    },
    error_messages: {
      type: [String],
      default: null
    },
    disabled: {
      type: Boolean
    }
  },
  watch: {
    value: function(newVal, oldVal) {
      this.ths_data = newVal;
    }
  },
  mounted() {
    this.ths_data = this.value;
  },
  data() {
    return {
      menu: false,
      ths_data: ''
    };
  },
  methods: {
    onChange() {
      this.$refs.menu.save(this.ths_data);
      this.$emit('onChange', this.ths_data);
    }
  }
};
</script>

<style lang="scss" scope>
.panel {
  position: relative;
}
.wrap-auto-complete {
  position: absolute;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.42);
  top: 40px;
  z-index: 20;
}
.box-suggestion {
  width: 100%;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  padding: 10px 10px;
  cursor: pointer;
}
.box-suggestion:hover {
  background-color: #f5f5f5;
}
</style>
